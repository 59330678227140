import React from 'react';
import {sortBy} from 'bigdatr-style';
import NzComingSoon from './articles/NzComingSoon';
import DataExportLaunch from './articles/DataExportLaunch';
import AdCompliance from './articles/AdCompliance';
import NzArrived from './articles/NzArrived';
import useFeatureFlags from '~/util/useFeatureFlags';

export default function useNewsItems() {
    const {nzMarket} = useFeatureFlags();
    const newsItems = sortBy(
        [
            ...(nzMarket
                ? [
                      {
                          date: new Date('2024-10-14'),
                          title: 'NZ Digital Creative - Now Available',
                          content: <NzArrived />
                      }
                  ]
                : []),
            {
                date: new Date('2024-08-15'),
                title: 'Mitigate Potential Risks with Advertising Compliance',
                content: <AdCompliance />
            },
            {
                date: new Date('2024-08-07'),
                title: 'New Update — Track Investment Behaviour and Media Growth',
                content: <DataExportLaunch />
            },
            {
                date: new Date('2024-06-25'),
                title: 'NZ Digital Creative - Coming Soon',
                content: <NzComingSoon />
            }
        ],
        (i) => i.date.getTime(),
        true
    );

    return newsItems;
}
