import {safeLazyImport, BoringRoute} from 'bigdatr-style';

const TrialExpired = safeLazyImport(() => import('~/no-access-routes/TrialExpired'));
const AdminOnlyTeam = safeLazyImport(() => import('~/no-access-routes/AdminOnlyTeam'));
const NoPermissions = safeLazyImport(() => import('~/no-access-routes/NoPermissions'));

export default {
    trialExpired: BoringRoute({
        path: '/trial-expired',
        component: TrialExpired
    }),
    adminOnlyTeam: BoringRoute({
        path: '/admin-only-team',
        component: AdminOnlyTeam
    }),
    noPermissions: BoringRoute({
        path: '/no-access',
        component: NoPermissions
    })
};
