import {Viewer} from 'bigdatr-style';
import {SupportedCountry} from 'bigdatr-style/src/team/Team';

export default function formatViewerDataToHubspotData(viewer: Viewer) {
    return {
        firstname: viewer.user.firstname,
        email: viewer.user.username,
        bigdatr_team_names: viewer.teams.map((t) => t.name).join(';'),
        bigdatr_permissions: {
            MediaValue: viewer.currentTeam.mediaValueIndustryNames,
            AdvertisingCreative: viewer.currentTeam.advertisingCreativeIndustryNames(
                SupportedCountry.AU
            )
        }
    };
}
