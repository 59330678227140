import {useLocalStorage} from 'bigdatr-style';
import {useBetween} from 'use-between';
import type {MediaValueMode} from '~/feature/media-value/affordance/MediaValuePageWrapper';

function useLastUsedMediaValuePage() {
    const [lastUsedMediaValuePage, setLastUsedMediaValuePage] = useLocalStorage<MediaValueMode>(
        'bd-app-lastUsedMediaValuePage'
    );

    return [lastUsedMediaValuePage, setLastUsedMediaValuePage] as const;
}

export default function () {
    return useBetween(useLastUsedMediaValuePage);
}
