import React from 'react';
import {Absolute, Relative} from '../../layout';
import {DefaultTheme} from 'styled-components';

type Bar = {
    value?: number;
    color?: keyof DefaultTheme['colors'];
    testId?: string;
};

type Props = {
    height?: string | number;
    backgroundBar?: Bar;
    mainBar: Bar;
};

export default function ProgressBar(props: Props) {
    const {backgroundBar, mainBar, height = '.3rem'} = props;

    return (
        <Relative
            height={height}
            width="100%"
            children={
                <>
                    <Absolute
                        data-testid={backgroundBar?.testId}
                        // default background bar to be full width
                        width={`${(backgroundBar?.value ?? 1) * 100}%`}
                        backgroundColor={backgroundBar?.color ?? 'outline'}
                        height="100%"
                    />
                    <Absolute
                        data-testid={mainBar.testId}
                        width={`${(mainBar.value ?? 1) * 100}%`}
                        backgroundColor={mainBar.color ?? 'brand'}
                        height="100%"
                    />
                </>
            }
        />
    );
}
