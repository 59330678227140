type ChangesetInput = {
    id: string;
    name: string;
    status: 'LIVE' | 'PREVIOUS' | 'DRAFT_READY';
};
export default class Changeset {
    private data: ChangesetInput;
    type = 'changeset' as const;

    constructor(input: ChangesetInput) {
        this.data = input;
    }

    get id() {
        return this.data.id;
    }

    get label() {
        return this.data.name;
    }

    get status() {
        return this.data.status;
    }
}
