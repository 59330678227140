import React from 'react';
import {Absolute, Box, Flex} from '../../layout';
import Styled from 'styled-components';
import Clickable from './Clickable';
import {IconChevronLeft, IconChevronRight} from '../../icon';
import useHotkey from '../util/useHotkey';

const Wrapper = Styled(Box)`
    margin: auto;
    width: 100%;
    height: 100%;
`;

export default function LightboxWrapper(props: {
    /** The media with a black background */
    content: React.ReactNode;
    /** The white bit with all the details */
    details?: React.ReactNode;
    singleColumn?: boolean;
    reverseOrder?: boolean;
    minHeight?: string;
    onNext?: () => void;
    onPrevious?: () => void;
    ratio?: [string, string];
    removeDetailsPadding?: boolean;
}) {
    const {content} = props;
    const {details} = props;
    const {singleColumn} = props;
    const {reverseOrder} = props;
    const {onPrevious} = props;
    const {onNext} = props;
    const {ratio = ['70%', '30%']} = props;

    const column = reverseOrder ? 'column-reverse' : 'column';
    const row = reverseOrder ? 'row-reverse' : 'row';
    const ref = useHotkey<HTMLDivElement>(
        {
            right: () => onNext?.(),
            left: () => onPrevious?.()
        },
        [onNext, onPrevious]
    );

    return (
        <Wrapper
            ref={ref}
            display="flex"
            flexDirection={{_: column, lg: singleColumn ? column : row}}
            alignItems="stretch"
            justifyContent="flex-start"
        >
            <Flex
                width={singleColumn ? undefined : {lg: ratio[0]}}
                backgroundColor="realBlack"
                px={onPrevious || onNext ? 4 : undefined}
                flexDirection="column"
                minHeight={props.minHeight}
                maxHeight="inherit"
                justifyContent="center"
                position="relative"
            >
                {onPrevious && (
                    <ArrowButton
                        left="0"
                        onClick={onPrevious}
                        children={<IconChevronLeft size="3rem" color="white" />}
                    />
                )}
                {content}
                {onNext && (
                    <ArrowButton
                        right="0"
                        onClick={onNext}
                        children={<IconChevronRight size="3rem" color="white" />}
                    />
                )}
            </Flex>
            <Box
                width={singleColumn ? undefined : {lg: ratio[1]}}
                p={!props.removeDetailsPadding ? {_: 3, lg: 4} : undefined}
                backgroundColor="background"
                overflowY={{lg: singleColumn ? undefined : 'auto'}}
                children={details}
            />
        </Wrapper>
    );
}

function ArrowButton(props: {children: any; left?: string; right?: string; onClick?: () => void}) {
    return (
        <Absolute
            left={props.left}
            right={props.right}
            width="4rem"
            display="flex"
            alignItems="stretch"
            zIndex="10"
            height="100%"
        >
            <Clickable width="100%" onClick={props.onClick}>
                {props.children}
            </Clickable>
        </Absolute>
    );
}
