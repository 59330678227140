import {safeLazyImport} from 'bigdatr-style';
import {LazyRoute, Parse} from 'bigdatr-style';
import {NewsArgs} from '~/feature/notifications/news/News';

const News = safeLazyImport(() => import('~/feature/notifications/news/News'));

export default {
    notificationNews: LazyRoute<NewsArgs>({
        path: '/notifications/news',
        parse: {
            firstname: Parse.query.string((x) => x),
            email: Parse.query.string((x) => x),
            bigdatr_team_names: Parse.query.string((x) => x),
            bigdatr_permissions: Parse.query.JSON((x) => x as NewsArgs['bigdatr_permissions'])
        },
        component: News
    })
};
