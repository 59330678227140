import {useHubspotForm} from '@aaronhayes/react-use-hubspot-form';
import {
    ErrorBoundary,
    Spinner,
    styled,
    Tooltip,
    useAnalytics,
    useLocalStorage
} from 'bigdatr-style';
import React, {useEffect, useState} from 'react';

type Props = {formId: string; targetId: string; openChatOnSubmit?: boolean};
export default function HubspotFormErrorBoundary(props: Props) {
    return (
        <ErrorBoundary>
            <HubspotForm {...props} />
        </ErrorBoundary>
    );
}

function HubspotForm(props: Props) {
    // if a user submits a form, we will disable submitting it in the future on the same device
    const [formAlreadySubmitted, setFormAlreadySubmitted] = useLocalStorage<boolean>(
        `bd-app-form-${props.targetId}-submitted`
    );

    // we're tracking if the user submitted the form in the current page visit, because we want to
    // show the tooltip over the form only if the user returns in a another page visit.
    // we do this because on a successful form submit, we display a message that the form is submitted
    const [formSubmittedInCurrentSession, setFormSubmittedInCurrentSession] = useState(false);

    const analytics = useAnalytics();

    const {loaded, error} = useHubspotForm({
        portalId: '6080063',
        formId: props.formId,
        target: `#${props.targetId}`,
        onFormReady: (formNode: HTMLFormElement) => {
            const elements = formNode.elements || [];

            // disable submitting the form if its already been submitted on this machine
            for (let i = 0; i < elements.length; i++) {
                const currentInput = elements[i];
                if (currentInput.getAttribute('type') !== 'submit') continue;
                if (formAlreadySubmitted) currentInput.setAttribute('disabled', 'true');
            }
        },
        onFormSubmit: () => {
            setFormAlreadySubmitted(true);
            setFormSubmittedInCurrentSession(true);
            if (props.openChatOnSubmit) analytics.chat();
        }
    });

    useEffect(() => {
        // `useHubspotForm` hook doesn't expose error details, just a boolean if an error ocurred
        if (error) analytics.trackError(new Error(`News form ${props.formId} errored`));
    }, [error]);

    if (error) return <span>Form failed to load.</span>;

    const showTooltip = !formSubmittedInCurrentSession && formAlreadySubmitted;
    return (
        <FormWrapper>
            <Tooltip
                cursor={showTooltip ? 'not-allowed' : undefined}
                content={showTooltip ? 'You have already submitted this form' : undefined}
            >
                <div id={props.targetId}>{!loaded && <Spinner />}</div>
            </Tooltip>
        </FormWrapper>
    );
}

const FormWrapper = styled.div`
    ${(p) => {
        const {brand, white, foreground2, background1} = p.theme.colors;
        return `
            display: flex;
            width: max-content;

            form {
                /* Copied from Button.tsx */
                input[type='submit'] {
                    background-color: ${brand};
                    border: 2px solid;
                    border-color: ${brand};
                    border-radius: 10rem;
                    box-sizing: border-box;
                    color: ${white};
                    cursor: pointer;
                    font-family: inherit;
                    line-height: 1;
                    height: 2rem;
                    padding: 0 1rem;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    white-space: nowrap;
                    min-width: 2rem;

                    &:hover {
                        opacity: 0.7;
                        outline: none;
                    }

                    &:focus {
                        outline: none;
                    }

                    &[disabled] {
                        cursor: default;
                        color: ${foreground2};
                        background-color: ${background1};
                        border: 2px solid ${background1};
                        opacity: 1;
                        
                        &:hover {
                            color: ${foreground2};
                            border-color: ${background1};
                            opacity: 1;
                            box-shadow: none;
                        }
                    }
                }
            }
    `;
    }}
`;
