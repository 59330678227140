type AdvertiserDomainInput = {
    name: string;
};
export default class AdvertiserDomain {
    private data: AdvertiserDomainInput;
    type = 'advertiserDomain' as const;

    constructor(input: AdvertiserDomainInput) {
        this.data = input;
    }

    get id() {
        return this.data.name;
    }

    get label() {
        return this.data.name;
    }
}
