import {ApiError} from 'bigdatr-style';

type QueryModule = () => Promise<{default: string}>;
type Config = {strict?: boolean};
export type Meta = {
    responseKey: string;
    authorizedFetch: (url: string, payload: any) => Promise<any>;
    getState: () => any;
};

function createEndpoint(url: string) {
    return <Variables = unknown>(getQueryModule: QueryModule, config: Config = {}) =>
        async (variables: Variables, meta: Meta) => {
            const {strict = false} = config;
            const responseKey = meta.responseKey;
            const queryModule = await getQueryModule();
            const start = new Date();
            const query = queryModule.default;

            return meta.authorizedFetch(url, {query, variables}).then(({errors, data}) => {
                const end = new Date();
                const responseMeta = {
                    id: responseKey,
                    start,
                    end,
                    url,
                    query,
                    variables,
                    errors
                };

                if (strict && (errors || []).length > 0) {
                    throw new ApiError(errors[0]);
                }

                return {
                    responseMeta,
                    ...data
                };
            });
        };
}

const EntityApi = createEndpoint(process.env.BIGDATR_ENTITY_API_ENDPOINT || '');
export const EntityStrict = <T = unknown>(query: QueryModule) =>
    EntityApi<T>(query, {strict: true});

export default {
    EntityApi: EntityApi,
    EntityStrict
};
