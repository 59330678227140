import {useBetween} from 'use-between';
import {useLocalStorage} from 'bigdatr-style';

export const openNavWidth = '16rem';
export const closedNavWidth = '3.625rem';
function useSidebarState() {
    const [navOpenLocalStorage, setNavOpen] = useLocalStorage('bd-app-sidebarOpen', false);
    const navOpen = Boolean(navOpenLocalStorage);
    const navWidth = navOpen ? openNavWidth : closedNavWidth;

    return {
        navOpen,
        setNavOpen,
        navWidth
    };
}

export default function () {
    return useBetween(useSidebarState);
}
