import {useEffect, useRef} from 'react';

/**
Pull a value out of the render cycle so you can compare the previous
value with the current one from props
@link https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
*/
export default function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
