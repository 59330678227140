import {ReportUser, ReportSharing} from '~/graphqlTypes';
import ShareModel from '~/affordance/sharing/ShareModel';
import {ReportItem} from './ReportItemDefinitionTypes';

type ReportInput = {
    id: string;
    user?: ReportUser;
    teamId: string;
    teamName?: string;
    name: string;
    description?: string;
    iconName: string;
    iconColor: string;
    createdAt: string;
    updatedAt: string;
    status: string;
    sharing: ReportSharing;
    items: Array<ReportItem>;
    country: string;
};

export default class Report extends ShareModel {
    readonly id: string;
    readonly user?: ReportUser;
    readonly teamId: string;
    readonly teamName: string;
    readonly name: string;
    readonly description?: string;
    readonly iconName: string;
    readonly iconColor: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly status: string;
    readonly items: Array<ReportItem>;
    readonly country: string;

    constructor(input: ReportInput) {
        super();
        this.id = input.id;
        this.user = input.user;
        this.teamId = input.teamId;
        this.teamName = input.teamName || 'Unknown team';
        this.name = input.name;
        this.description = input.description;
        this.iconName = input.iconName;
        this.iconColor = input.iconColor;
        this.createdAt = input.createdAt;
        this.updatedAt = input.updatedAt;
        this.sharing = input.sharing;
        this.status = input.status;
        this.items = input.items;
        this.country = input.country;
    }

    static defaultName = 'Untitled Report';
    static defaultDescription =
        'Enter a description. This can help others better understand your report.';

    static defaultItemName = 'Untitled Report Item';
    static defaultItemDescription =
        'Enter a description. This can help others better understand this report item.';
}
