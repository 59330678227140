// name = `Suburb State Postcode`
type LocationInput = {
    name: string;
};
export default class Location {
    private data: LocationInput;
    type = 'location' as const;

    constructor(input: LocationInput) {
        this.data = input;
    }

    get id() {
        return this.data.name;
    }

    get label() {
        return this.data.name;
    }
}
