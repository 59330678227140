import React, {MouseEvent, useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import fullscreenHelper from './Fullscreen';
import {breakpoint} from '../../core/themes/themeGetters';
import {IconPause} from '../../../icon';
import {IconPlay} from '../../../icon';
import {IconMute} from '../../../icon';
import {IconUnmute} from '../../../icon';
import {IconFullscreen} from '../../../icon';
import {IconUnfullscreen} from '../../../icon';
import {default as IconMediaTypeRadio} from '../../icon/mediaType/Radio';
import {Box, Flex, Absolute} from '../../../layout';
import Clickable from '../Clickable';
import Text from '../Text';
import ProgressBar from '../ProgressBar';
import useMedia from '../../util/useMedia';
import useHotkey from '../../util/useHotkey';
import useIntersectionObserver from '../../util/useIntersectionObserver';

export type Props = {
    autoPlay?: boolean;
    autoPlayWhenInViewport?: boolean;
    modifier?: string;
    minHeight?: string;
    poster?: string;
    src: string;
    type: 'video' | 'audio';
    trackFullscreen?: Function;
    onRemoveFirstPlay?: (playPause: () => any) => any;
    style?: Record<string, any>;
    showAudioControl?: boolean;
    muted?: boolean;
};

const Controls = styled.div<{isPlaying: boolean}>`
    position: absolute;
    padding: 0.5rem;
    width: 100%;
    bottom: 0;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
    transition: opacity 320ms 500ms;
    color: white;

    ${(props) => (props.isPlaying ? 'opacity: 0;' : '')}

    @media (min-width: ${breakpoint('md')}) {
        padding: 1rem;
    }
`;

const Progress = styled.div`
    position: absolute;
    bottom: 2.5rem;
    left: 0;
    right: 0;

    @media (min-width: ${breakpoint('md')}) {
        bottom: 2.5rem;
        left: 1rem;
        right: 1rem;
        border-top: 1rem solid transparent;
        border-bottom: 1rem solid transparent;
    }
`;

const MediaPlayerWrapper = styled.div<{
    isAudio: boolean;
    minHeight?: string;
}>`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(p) => p.theme.colors.realBlack};

    ${(props) => (props.isAudio ? 'min-height: 20rem;' : '')}
    min-height: ${(p) => p.minHeight};

    &:focus {
        outline: none !important;
    }

    &:hover ${Controls} {
        opacity: 1;
    }
`;

const Fullscreen = () => <IconFullscreen size="1.5rem" aria-label="Enter fullscreen" />;
const Unfullscreen = () => <IconUnfullscreen size="1.5rem" aria-label="Exit fullscreen" />;
const Play = (props: {size?: string}) => (
    <IconPlay size={props.size ?? '1.5rem'} aria-label="Click to play" />
);
const Pause = () => <IconPause size="1.5rem" aria-label="Click to pause" />;
const Mute = () => <IconMute size="1.5rem" aria-label="Click to unmute" />;
const Unmute = () => <IconUnmute size="1.5rem" aria-label="Click to mute" />;

export default function MediaPlayer(props: Props) {
    const {autoPlay, minHeight, poster, src, type = 'video', showAudioControl = true} = props;

    // style to pass to media
    const mediaStyle: Record<string, any> = {
        maxHeight: 'calc(100vh - 4rem)',
        maxWidth: '100%',
        ...props.style
    };

    // Create video or audio and get controls
    const {media, mediaControls, mediaState, mediaRef} = useMedia({
        tag: type,
        src: src,
        poster: poster,
        autoPlay: autoPlay,
        style: mediaStyle,
        muted: props.muted
    });

    // State
    const [scrubbing, setScrubbing] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const hotkeyRef = useHotkey<HTMLDivElement>({
        space: mediaControls.togglePlayState
    });

    // Refs
    const player = mediaRef.current;
    const progressRef = useRef<HTMLDivElement>(null);
    const progress = progressRef.current;
    const mediaWrapperRef = useRef<HTMLDivElement>(null);

    // Time indicators
    const currentMinutes = Math.floor((mediaState.time % 3600) / 60);
    const currentSeconds = Math.floor((mediaState.time % 3600) % 60);
    const durationMinutes = Math.floor((mediaState.duration % 3600) / 60);
    const durationSeconds = Math.floor((mediaState.duration % 3600) % 60);

    // Callbacks
    const onFullscreen = () => {
        if (player && fullscreenHelper.enabled()) {
            const active = fullscreenHelper.active();
            setFullscreen(active);
            props?.trackFullscreen?.();
            return active ? fullscreenHelper.exit() : fullscreenHelper.request(player);
        }
    };

    const onScrub = (ee: MouseEvent) => {
        if (ee.type === 'mousedown') setScrubbing(true); // mouse up is handled globally
        // Checking for `player.duration` as well because it can be NaN
        if (progress && player && player.duration && scrubbing) {
            // if elements exist and we are scrubbing
            // calculate the current time under the mouse
            const x = ee.clientX - progress.getBoundingClientRect().left;
            const width = progress.offsetWidth;
            const xClamped = Math.max(0, Math.min(x, width));
            const currentTime = (xClamped / width) * player.duration;
            player.currentTime = currentTime;
        }
    };

    // Auto play when in viewport if enabled
    const mediaObserver = useIntersectionObserver(mediaWrapperRef, {
        freezeOnceVisible: true,
        enabled: props.autoPlayWhenInViewport
    });
    if (mediaObserver?.isIntersecting && mediaState.firstPlay) mediaControls.play();

    // buffering and global mouse up event listeners
    useEffect(() => {
        const hasDocument = typeof document !== 'undefined';

        // Global mouse up
        const stopScubbing = () => setScrubbing(false);
        if (hasDocument) document.addEventListener('mouseup', stopScubbing);

        // Remove listeners
        return () => {
            if (hasDocument) document.removeEventListener('mouseup', stopScubbing);
        };
    }, [src]);

    useEffect(() => {
        if (player) {
            // Settled on this by listening to some music and youtubes
            // and just vibing that the volume was about the same
            player.volume = 0.4;
            player.muted = mediaState.muted;
        }
    }, [src, player]);

    return (
        <MediaPlayerWrapper
            isAudio={type === 'audio'}
            tabIndex={0}
            minHeight={minHeight}
            ref={mediaWrapperRef}
        >
            <Flex
                onClick={mediaControls.togglePlayState}
                justifyContent="center"
                alignItems="center"
                flex={1}
                alignSelf="stretch"
            >
                {type === 'audio' && <IconMediaTypeRadio color="white" size="6rem" />}
                {media}
            </Flex>

            {mediaState.firstPlay ? (
                <Absolute
                    as="button"
                    cursor="pointer"
                    display="flex"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    backgroundColor="rgba(0, 0, 0, .1)"
                    color="white"
                    alignItems="center"
                    justifyContent="center"
                    onClick={mediaControls.togglePlayState}
                    aria-label="Play"
                >
                    <Play size="6rem" />
                </Absolute>
            ) : (
                <Controls
                    ref={hotkeyRef}
                    isPlaying={!mediaState.paused}
                    style={{
                        zIndex: 2 //This is to place it over the navigation buttons on CreativeItemView
                    }}
                >
                    <Flex gap={3}>
                        <Clickable onClick={mediaControls.togglePlayState}>
                            {mediaState.paused ? <Play /> : <Pause />}
                        </Clickable>

                        <Box flexGrow={1} data-testid="elapsed-time">
                            <Text numberFormat="00">{currentMinutes}</Text>
                            <Text>:</Text>
                            <Text numberFormat="00">{currentSeconds}</Text>
                            <Text> / </Text>
                            <Text numberFormat="00">{durationMinutes}</Text>
                            <Text>:</Text>
                            <Text numberFormat="00">{durationSeconds}</Text>
                        </Box>
                        {showAudioControl && (
                            <Clickable onClick={mediaControls.toggleMuteState}>
                                {mediaState.muted ? <Mute /> : <Unmute />}
                            </Clickable>
                        )}
                        {type !== 'audio' && (
                            <Clickable onClick={onFullscreen}>
                                {fullscreen ? <Unfullscreen /> : <Fullscreen />}
                            </Clickable>
                        )}
                    </Flex>
                    <Progress
                        ref={progressRef}
                        onMouseDown={onScrub}
                        onMouseMove={onScrub}
                        onMouseUp={onScrub}
                    >
                        <ProgressBar
                            backgroundBar={{
                                color: 'muted',
                                value: (mediaState.buffered ?? 0) / mediaState.duration,
                                testId: 'MediaTotalDurationBar'
                            }}
                            mainBar={{
                                value: (mediaState.time ?? 0) / mediaState.duration,
                                testId: 'MediaElapsedTimeBar'
                            }}
                        />
                    </Progress>
                </Controls>
            )}
        </MediaPlayerWrapper>
    );
}
