import Endpoints from '~/app/Endpoints';
import updateIn from 'unmutable/updateIn';
import type {Meta} from '~/app/Endpoints';
import {EntyRequest, GraphqlRequest, ResponseMeta, Team} from 'bigdatr-style';
import {TeamMember, Schemas} from 'bigdatr-style';
import {ObjectSchema} from 'react-enty';

const {EntityStrict} = Endpoints;

const teamItem = EntityStrict(() => import('~/user/data/TeamItemQuery.graphql'));
const teamUpdate = EntityStrict(() => import('~/user/data/TeamUpdateMutation.graphql'));
const teamRemove = EntityStrict(() => import('~/user/data/TeamRemoveMutation.graphql'));
const teamList = EntityStrict(() => import('~/user/data/TeamListQuery.graphql'));
const teamCreateWithInvite = EntityStrict(
    () => import('~/user/data/TeamCreateWithInviteMutation.graphql')
);
const teamMemberUpdate = EntityStrict(() => import('~/user/data/TeamMemberUpdateMutation.graphql'));
const teamMemberRemove = EntityStrict(() => import('~/user/data/TeamMemberRemoveMutation.graphql'));
const teamMemberInvite = EntityStrict(() => import('~/user/data/TeamMemberInviteMutation.graphql'));
const teamMemberAdd = EntityStrict(() => import('~/user/data/TeamMemberAddMutation.graphql'));
const teamInviteResend = EntityStrict(() => import('~/user/data/TeamInviteResendMutation.graphql'));
const teamInviteRemove = EntityStrict(() => import('~/user/data/TeamInviteRemoveMutation.graphql'));
const teamUpdateProducts = EntityStrict(
    () => import('~/user/data/TeamUpdateProductsMutation.graphql')
);
const teamUpdateTrialIndustryGroup = EntityStrict(
    () => import('~/user/data/TeamUpdateTrialIndustryGroupMutation.graphql')
);
const teamAddNzTrial = EntityStrict(() => import('~/user/data/TeamAddNzTrial.graphql'));

type TeamInvite = {
    username: string;
    teamId: string;
    inviterId: string;
    role: 'MEMBER' | 'ADMIN';
};

type TeamInviteErrorResponse = {
    username: string;
    error: string;
};

export default {
    teamItem,
    teamList,
    teamUpdate,
    /** Calls teamUpdate, but will normalize the updated team with the `team` and `viewerTeam` schemas. */
    teamOwnerTeamUpdate: async (vars: {id: string; name: string}, meta: Meta) => {
        const response = await teamUpdate(vars, meta);
        const team = response.team.teamUpdate;
        return {
            team: {teamUpdate: team},
            viewerTeam: team
        };
    },
    /** Normalize the updated team with the `team` and `viewerTeam` schemas. */
    teamUpdateTrialIndustryGroup: async (
        input: {teamId: string; industryGroup: string},
        meta: Meta
    ) => {
        const response = await teamUpdateTrialIndustryGroup({input}, meta);
        const team = response.team.teamUpdateTrialIndustryGroup;
        return {
            team: {teamUpdate: team},
            viewerTeam: team
        };
    },
    teamAddNzTrial: async (
        input: {formFields: Array<{name: string; value: string}>},
        meta: Meta
    ) => {
        const response = await teamAddNzTrial({input}, meta);
        const team = response.team.teamAddNzTrial;
        return {
            team: {teamUpdate: team},
            viewerTeam: team
        };
    },
    teamUpdateProducts: async (
        vv: {id: string; products: Array<unknown>},
        mm: Meta
    ): Promise<unknown> => {
        // NOTE: them teamUpdateProducts mutation returns a new list of products.
        // We can return a psuedo team here and let enty normalize the new products in.
        const {team} = await teamUpdateProducts(vv, mm);
        return {
            team: {team: {id: vv.id, products: team.teamUpdateProducts}}
        };
    },

    teamRemove: async (vv: unknown, mm: Meta): Promise<unknown> => {
        await teamRemove(vv, mm);
        return teamList(vv, mm);
    },
    teamInviteResend,
    teamInviteRemove: async (vv: unknown, mm: Meta): Promise<unknown> => {
        await teamInviteRemove(vv, mm);
        return teamItem(vv, mm);
    },
    teamMemberRemove: async (vv: unknown, mm: Meta): Promise<unknown> => {
        await teamMemberRemove(vv, mm);
        return teamItem(vv, mm);
    },
    teamMemberUpdate,
    teamCreate: async (vv: unknown, mm: Meta): Promise<unknown> => {
        // team create returns a single team. Make it an array so it can
        // be concatenated to the main list
        const data = await teamCreateWithInvite(vv, mm);

        const modifiedData = updateIn(['team', 'teamCreateWithInvite'], (team) => [team])(data);
        return {
            ...data,
            team: {
                // Change the team to array for normalizing the team list + add item for response usage
                teamCreateWithInvite: modifiedData.team.teamCreateWithInvite,
                team: data.team.teamCreateWithInvite
            }
        };
    },
    teamMemberAdd: async (
        members: Array<{userId: string; teamId: string; role: string}>,
        meta: Meta
    ) => {
        return members.reduce(
            (pp, member) => pp.then(() => teamMemberAdd({member}, meta)),
            Promise.resolve()
        );
    },
    teamMemberInvite: async (invites: Array<TeamInvite>, meta: Meta) => {
        const teamId = invites[0].teamId;

        const errors: TeamInviteErrorResponse[] = [];
        for (const invite of invites) {
            try {
                await teamMemberInvite({invite}, meta);
            } catch (e) {
                errors.push({username: invite.username, error: e.message});
            }
        }

        const team = await teamItem({teamId}, meta);
        team.inviteErrors = errors;
        return team;
    }
};

type TeamRequest<T> = EntyRequest<{team: T; responseMeta: ResponseMeta}>;
export type TeamApiType = {
    team: {
        teamCreate: TeamRequest<{teamCreateWithInvite: Team[]; team: Team}>;
        teamInviteResend: TeamRequest<{teamResendInvite: boolean}>;
        teamInviteRemove: TeamRequest<{teamInviteRemove: boolean}>;
        teamItem: TeamRequest<{team: Team}>;
        teamMemberAdd: TeamRequest<{teamAddUser: TeamMember}>;
        teamMemberInvite: TeamRequest<{teamInviteUser: any}>;
        teamMemberRemove: TeamRequest<{teamRemoveUser: boolean}>;
        teamMemberUpdate: TeamRequest<{teamUpdateUser: TeamMember}>;
        teamRemove: TeamRequest<{teamRemove: boolean}>;
        teamUpdate: TeamRequest<{teamUpdate: Team}>;
        teamOwnerTeamUpdate: TeamRequest<{teamUpdate: Team}>;
        teamUpdateProducts: TeamRequest<{teamList: Team[]}>;
        teamList: TeamRequest<{teamList: Team[]}>;
        teamUpdateTrialIndustryGroup: TeamRequest<{team: Team}>;
        teamAddNzTrial: GraphqlRequest<
            {team: {team: Team}},
            {formFields: Array<{name: string; value: string}>}
        >;
    };
};

const {team, teamList: teamListSchema, teamInvite, rootTeamListAdd, teamMember} = Schemas;
export const TeamSchema = {
    team: new ObjectSchema({
        team,
        teamList: teamListSchema,
        teamInviteUser: teamInvite,
        teamCreateWithInvite: rootTeamListAdd,
        teamUpdate: team,
        teamUpdateUser: teamMember,
        teamAddUser: teamMember
    })
};
