import {DropdownList, MenuItem, Viewer} from 'bigdatr-style';
import React from 'react';
import TeamAvatar from './TeamAvatar';

export default function DropdownSwitchTeams(props: {
    viewer: Viewer;
    changeTeam: (teamId: string) => void;
}) {
    const {viewer, changeTeam} = props;

    const teamDropdown =
        viewer.teamsWithAccess.length > 1 ? (
            <DropdownList>
                {viewer.teamsWithAccess
                    .filter((team) => team.id !== viewer.currentTeamId)
                    .map((team) => (
                        <MenuItem key={team.id} onClick={() => changeTeam(team.id)}>
                            <TeamAvatar team={team} subtitle="Switch to" reverseSubtitle />
                        </MenuItem>
                    ))}
            </DropdownList>
        ) : null;

    return teamDropdown;
}
