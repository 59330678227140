import {Viewer} from 'bigdatr-style';
import {AU, SupportedCountry} from '~/feature/country/CountrySwitch';

export default function shouldOnboardUser(viewer: Viewer, currentCountry: SupportedCountry) {
    const isOnTrial = viewer.isTrialing(currentCountry);
    // first user hasn't changed the team name yet
    const hasTrialTeamName = viewer.currentTeam.hasTrialTeamName === true;
    // first user hasn't selected an industry group yet
    const hasSelectedIndustries =
        viewer.currentTeam.productMap.Australia?.AdvertisingCreative?.hasSelectedIndustries ===
        true;

    // aussie logic
    if (currentCountry === AU) {
        return (
            // these checks are related to the first user on a new automated trial
            (isOnTrial && (hasTrialTeamName || !hasSelectedIndustries)) ||
            // this is specific to every user in an aussie team
            viewer.userOnboardingInfo.isAustraliaOnboarded === false
        );
    }

    // non aussie logic, only related to the first user on a new automated trial
    if (isOnTrial && hasTrialTeamName) {
        return true;
    }

    // team and user is onboarded
    return false;
}
