import React from 'react';
import {useState} from 'react';
import {useCallback} from 'react';
import Button from './Button';
import {IconCheck, IconCross} from '../../icon';
import Clickable from './Clickable';

type Props = {
    clipboardValue: string;
    clipboardLabel: React.ReactNode;
    mailtoSubject?: string;
    mailtoBody?: string;
    mailtoLabel?: string;
    onClick?: () => void;
    secondary?: boolean;
    tertiary?: boolean;
    minimal?: boolean;
    thin?: boolean;
    width?: string;
};

export default function ButtonShare(props: Props) {
    const {clipboardValue, mailtoLabel, mailtoBody, mailtoSubject} = props;

    // Set up async clipboard state
    // Note: there is intentionally no pending state, the api changes to fast to care
    const [state, setState] = useState<'empty' | 'error' | 'complete'>('empty');
    const onClick = useCallback(async () => {
        props.onClick?.();
        try {
            await navigator.clipboard.writeText(clipboardValue);
            setState('complete');
        } catch (e) {
            setState('error');
        } finally {
            setTimeout(() => setState('empty'), 2000);
        }
    }, []);

    const width = props.width ?? '100%';

    // Return a mailto button if the clipboard api is not available
    if (
        typeof window.navigator.clipboard === 'undefined' &&
        mailtoBody &&
        mailtoSubject &&
        mailtoLabel
    ) {
        return (
            <Button
                secondary={props.secondary}
                tertiary={props.tertiary}
                href={`mailto:?body=${encodeURIComponent(mailtoBody)}&subject=${encodeURIComponent(
                    mailtoSubject
                )}`}
                onClick={props.onClick}
                children={mailtoLabel}
                width={width}
                thin={props.thin}
            />
        );
    }

    let children = props.clipboardLabel;
    if (state === 'error') children = <IconCross size="1rem" />;
    if (state === 'complete') children = <IconCheck size="1rem" />;

    if (props.minimal)
        return (
            <Clickable width={width} onClick={onClick}>
                {children}
            </Clickable>
        );

    return (
        <Button
            secondary={props.secondary}
            tertiary={props.tertiary}
            onClick={onClick}
            children={children}
            width={width}
            thin={props.thin}
        />
    );
}
