import {EntitySchema, ObjectSchema, ArraySchema} from 'react-enty';
import {ClassSchema, GraphqlRequest, Schemas, Viewer} from 'bigdatr-style';
import {EntityStrict} from '~/app/Endpoints';
import Segment, {SegmentDefinition} from './Segment';
import {
    SegmentV2MutationSegmentCreateArgs,
    SegmentV2MutationSegmentUpdateArgs,
    SegmentV2MutationSegmentRemoveArgs,
    SegmentV2MutationNzUnsubscribeArgs,
    SegmentV2MutationSegmentRemoveManyArgs,
    SegmentV2MutationSegmentShareManyArgs,
    SegmentV2QuerySegmentArgs,
    SegmentV2QuerySegmentListArgs,
    SegmentV2MutationCreativeEmailSegmentUpdateArgs,
    SegmentV2QuerySegmentSearchArgs
} from '~/graphqlTypes';
import {viewerQuery} from '~/app/EntityApi';

const SegmentQuery = EntityStrict(() => import('./SegmentQuery.graphql'));
const SegmentListQuery = EntityStrict(() => import('./SegmentListQuery.graphql'));
const SegmentSearchQuery = EntityStrict(() => import('./SegmentSearchQuery.graphql'));
const SegmentUpdateMutation = EntityStrict(() => import('./SegmentUpdateMutation.graphql'));
const SegmentCreateMutation = EntityStrict<SegmentV2MutationSegmentCreateArgs>(
    () => import('./SegmentCreateMutation.graphql')
);

const SegmentRemoveMutation = EntityStrict(() => import(`./SegmentRemoveMutation.graphql`));
const SegementNzUnsubscribeMutation = EntityStrict(
    () => import('./SegmentNzUnsubsribeMutation.graphql')
);
const SegmentRemoveManyMutation = EntityStrict<SegmentV2MutationSegmentRemoveManyArgs>(
    () => import(`./SegmentRemoveManyMutation.graphql`)
);
const SegmentShareManyMutation = EntityStrict<SegmentV2MutationSegmentShareManyArgs>(
    () => import(`./SegmentShareManyMutation.graphql`)
);
const CreativeEmailSegment = EntityStrict(() => import(`./CreativeEmailSegment.graphql`));
const CreativeEmailSegmentUpdate = EntityStrict<SegmentV2MutationCreativeEmailSegmentUpdateArgs>(
    () => import(`./CreativeEmailSegmentUpdate.graphql`)
);

export const segment = new EntitySchema('segment');
const creativeEmailSegment = new EntitySchema('segment', {
    id: () => 'creativeEmailSegment'
});

const segmentList = new EntitySchema('segmentList', {
    shape: new ObjectSchema({
        data: new ArraySchema(segment)
    }),
    id: (x) => x.id
});

const userSegmentList = new ObjectSchema({
    data: new ArraySchema(segment)
});

function getSegmentShape() {
    return new ClassSchema(Segment, {
        definitionV2: new ObjectSchema({
            adType: Schemas.adTypeList,
            brand: Schemas.brandList,
            industry: Schemas.industryList,
            category: Schemas.categoryList,
            campaign: Schemas.campaignList,
            publication: Schemas.publicationList,
            publisher: Schemas.publisherList,
            region: Schemas.regionList,
            product: Schemas.productList,
            mediaOwner: Schemas.mediaOwnerList,
            mediaType: Schemas.mediaTypeList
        })
    });
}
segment.shape = getSegmentShape();
creativeEmailSegment.shape = getSegmentShape();

export const SegmentSchema = {
    segmentV2: new ObjectSchema({
        segment,
        segmentCreate: segment,
        segmentUpdate: segment,
        segmentList,
        segmentShareMany: userSegmentList,
        segmentRemoveMany: userSegmentList,
        creativeEmailSegment: creativeEmailSegment,
        creativeEmailSegmentCreate: new ObjectSchema({
            creativeEmailSegment,
            viewer: Schemas.viewer
        }),
        creativeEmailSegmentUpdate: creativeEmailSegment,
        followBrand: creativeEmailSegment
    })
};

export type SegmentApiType = {
    segmentV2: {
        segmentCreate: GraphqlRequest<
            {segmentV2: {segmentCreate: Segment}},
            SegmentV2MutationSegmentCreateArgs
        >;
        segmentUpdate: GraphqlRequest<
            {segmentV2: {segmentUpdate: Segment}},
            SegmentV2MutationSegmentUpdateArgs
        >;
        segmentRemove: GraphqlRequest<
            {segmentV2: {segmentRemove: boolean}},
            SegmentV2MutationSegmentRemoveArgs
        >;
        segmentRemoveMany: GraphqlRequest<
            {segmentV2: {segmentRemoveMany: {data: Array<Segment>}}},
            SegmentV2MutationSegmentRemoveManyArgs
        >;
        segmentShareMany: GraphqlRequest<
            {segmentV2: {segmentShareMany: {data: Array<Segment>}}},
            SegmentV2MutationSegmentShareManyArgs
        >;
        segment: GraphqlRequest<{segmentV2: {segment: Segment}}, SegmentV2QuerySegmentArgs>;
        segmentList: GraphqlRequest<
            {segmentV2: {segmentList: {data: Array<Segment>}}},
            SegmentV2QuerySegmentListArgs
        >;
        segmentSearch: GraphqlRequest<
            {segmentV2: {segmentSearch: {data: Array<Segment>}}},
            SegmentV2QuerySegmentSearchArgs
        >;
        creativeEmailSegment: GraphqlRequest<
            {segmentV2: {creativeEmailSegment: Segment | null}},
            {input: {fullPrimitive: boolean}} | void
        >;
        creativeEmailSegmentCreate: GraphqlRequest<
            {segmentV2: {creativeEmailSegmentCreate: Segment | null}; viewer: Viewer},
            CreativeEmailSegmentInput & {teamId: string}
        >;
        creativeEmailSegmentUpdate: GraphqlRequest<
            {segmentV2: {creativeEmailSegmentUpdate: Segment | null}},
            CreativeEmailSegmentInput & {id: string}
        >;
        followBrand: GraphqlRequest<
            {segmentV2: {creativeEmailSegmentUpdate: Segment | null}},
            FollowBrandInput
        >;
        nzUnsubscribe: GraphqlRequest<
            {segmentV2: {nzUnsubscribe: boolean}},
            SegmentV2MutationNzUnsubscribeArgs
        >;
    };
};

export const SegmentApi = {
    segmentV2: {
        segmentCreate: SegmentCreateMutation,
        segmentUpdate: SegmentUpdateMutation,
        segmentRemove: SegmentRemoveMutation,
        nzUnsubscribe: SegementNzUnsubscribeMutation,
        segmentRemoveMany: SegmentRemoveManyMutation,
        segmentShareMany: SegmentShareManyMutation,
        segment: SegmentQuery,
        segmentList: async (vars: SegmentV2QuerySegmentListArgs, meta) => {
            const response = await SegmentListQuery(vars, meta);

            const r = {
                ...response,
                segmentV2: {
                    segmentList: {
                        // @intent - we use segments to send daily creative emails. this segment
                        // should not be edited through the "classic" segment UI, but only through
                        // onboarding or notification pages
                        data: response.segmentV2.segmentList.data.filter(
                            (s) => s.name !== Segment.secretCreativeEmailSegmentName
                        )
                    }
                }
            };
            r.segmentV2.segmentList.id = JSON.stringify([
                vars.input?.country,
                vars.input?.includeDeleted,
                vars.input?.idList,
                vars.input?.ignoreSharing,
                vars.input?.requestFullPrimitives
            ]);

            return r;
        },
        segmentSearch: SegmentSearchQuery,
        creativeEmailSegment: CreativeEmailSegment,
        // creates a segment and then refetches viewer. this isn't great, however since the
        // onboarding info is read from the viewer, its safest to refetch the viewer, and then
        // IndexView will render appropriate routes if the user has created a keyword segment
        creativeEmailSegmentCreate: async function (
            input: CreativeEmailSegmentInput & {teamId: string},
            meta
        ) {
            const response = await SegmentCreateMutation(
                {
                    input: {
                        name: Segment.secretCreativeEmailSegmentName,
                        emailAlert: true, // enable notification by default
                        definitionV2: {
                            brand: input.brand,
                            category: input.category
                        }
                    }
                },
                meta
            );

            const viewerResponse = await viewerQuery({teamId: input.teamId}, meta);

            return {
                ...response,
                segmentV2: {creativeEmailSegmentCreate: response.segmentV2.segmentCreate},
                viewer: viewerResponse.viewer
            };
        },
        creativeEmailSegmentUpdate: async function (
            input: CreativeEmailSegmentInput & {id: string},
            meta
        ) {
            return CreativeEmailSegmentUpdate(
                {
                    input: {
                        id: input.id,
                        brand: input.brand,
                        category: input.category,
                        emailAlert: input.emailAlert
                    }
                },
                meta
            );
        },
        /** We gotta accept full entities here, because we are not using the API response for
         * normalizing, but instantly returning the input for API */
        followBrand: function* (input: FollowBrandInput, meta) {
            yield {
                segmentV2: {
                    creativeEmailSegmentUpdate: {
                        id: input.id,
                        emailAlert: input.emailAlert,
                        definitionV2: {
                            brand: input.brand,
                            category: input.category
                        }
                    }
                }
            };
            return CreativeEmailSegmentUpdate(
                {
                    input: {
                        id: input.id,
                        brand: input.brand?.map((e) => e.id),
                        category: input.category?.map((e) => e.id),
                        emailAlert: input.emailAlert
                    }
                },
                meta
            );
        }
    }
};

type CreativeEmailSegmentInput = {
    brand?: string[];
    category?: string[];
    emailAlert?: boolean;
};
type FollowBrandInput = {
    id: string;
    brand?: SegmentDefinition['brand'];
    category?: SegmentDefinition['category'];
    emailAlert?: boolean;
};
