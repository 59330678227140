import {safeLazyImport} from 'bigdatr-style';
import {LazyRoute, Parse} from 'bigdatr-style';
import type {QueryStringFilters} from '~/feature/filter/types';
import {VerticalAnalysisArgs} from '~/feature/media-value/VerticalAnalysisPage';
import {MediaValuePageArgs} from '~/pages/explore-media-value/types';
import {GrowthAnalysisArgs} from './GrowthAnalysisPage';

const ExploreMediaValuePage = safeLazyImport(
    () => import('~/feature/media-value/ExploreMediaValuePage')
);
const Breakdown = safeLazyImport(() => import('~/feature/media-value/ExpenditureReportPage'));
const GrowthAnalysis = safeLazyImport(() => import('~/feature/media-value/GrowthAnalysisPage'));
const VerticalAnalysis = safeLazyImport(() => import('~/feature/media-value/VerticalAnalysisPage'));

export default {
    /** @deprecated This route now only serves a legacy purpose of handling redirects in a nice way to the customer. Use one of the more specific media value routes below */
    exploreMediaValue: LazyRoute<MediaValuePageArgs>({
        path: '/explore-media-value',
        parse: {
            filter: Parse.query.JSON((x) => x as QueryStringFilters),
            columns: Parse.query.JSON((x) => (Array.isArray(x) ? x : ['brand'])),
            excludedColumns: Parse.query.JSON((x) => (Array.isArray(x) ? x : [])),
            chartType: Parse.query.string((x) =>
                x ? (x as MediaValuePageArgs['chartType']) : 'Matrix'
            ),
            changesetId: Parse.query.number((x) => x)
        },
        component: ExploreMediaValuePage
    }),
    mediaValueExpenditureReport: LazyRoute<MediaValuePageArgs>({
        path: '/explore-media-value/expenditure-report',
        parse: {
            filter: Parse.query.JSON((x) => x as QueryStringFilters),
            columns: Parse.query.JSON((x) => (Array.isArray(x) ? x : ['brand'])),
            excludedColumns: Parse.query.JSON((x) => (Array.isArray(x) ? x : [])),
            chartType: Parse.query.string((x) =>
                x ? (x as MediaValuePageArgs['chartType']) : 'Matrix'
            ),
            changesetId: Parse.query.number((x) => x)
        },
        component: Breakdown
    }),
    mediaValueGrowthAnalysis: LazyRoute<GrowthAnalysisArgs>({
        path: '/explore-media-value/growth-analysis',
        parse: {
            rows: Parse.query.JSON((x) => x as GrowthAnalysisArgs['rows']),
            dateRangeFilter: Parse.query.JSON((x) => x as GrowthAnalysisArgs['dateRangeFilter'])
        },
        component: GrowthAnalysis
    }),
    mediaValueVerticalAnalysis: LazyRoute<VerticalAnalysisArgs>({
        path: '/explore-media-value/vertical-analysis',
        parse: {
            rows: Parse.query.JSON((x) => (x as VerticalAnalysisArgs['rows']) ?? []),
            date: Parse.query.JSON((x) => x as VerticalAnalysisArgs['date']),
            breakdownColumn: Parse.query.string((x) => x as VerticalAnalysisArgs['breakdownColumn'])
        },
        component: VerticalAnalysis
    })
};
