import React from 'react';
import styled from 'styled-components';
import {Fixed, Flex} from '../../layout';

const ChatHeadWrapper = styled(Fixed)`
    display: initial;
    right: 0;
    bottom: 0;
    z-index: 2147483647;
`;

const ChatHeadBubble = styled(Flex)`
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px, rgba(0, 0, 0, 0.2) 0px 2px 24px;
    border-radius: 50%;
    margin: auto 1rem 1rem auto;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function ChatHeadPlaceholder() {
    // Note the purpose of this component is to have a placeholder for
    // the hubspot chat head to allow for accurate recreation of prod-like environment.
    // The styles are copies of the styling used on prod chat head
    return (
        <ChatHeadWrapper height={96} width={100} id="hubspot-messages-iframe-container">
            <ChatHeadBubble width={60} height={60} bg="brand">
                <img
                    alt="Open live chat"
                    src="//static.hsappstatic.net/conversations-visitor-experience-components/static-1.2545/img/visitor-widget/launcher-open-icon.svg"
                    width={32}
                />
            </ChatHeadBubble>
        </ChatHeadWrapper>
    );
}
