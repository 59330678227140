import {Fragment, useState} from 'react';
import {useParams} from 'react-router-dom';
import api from '~/app/EntityApi';
import {Badge, Clickable, Creative, Loader, styled, useViewerUnsafe} from 'bigdatr-style';
import React, {useEffect} from 'react';
import useEventTracking from '~/app/useEventTracking';
import {Box, Flex, FlexCenter, Grid, Wrapper} from 'bigdatr-style/layout';
import {CreativePreview} from 'bigdatr-style';
import {Lightbox} from 'bigdatr-style';
import {Link} from 'bigdatr-style';
import {TableKeyValue} from 'bigdatr-style';
import {Button} from 'bigdatr-style';
import {Text} from 'bigdatr-style';
import {chunk} from 'bigdatr-style';
import {ButtonShare} from 'bigdatr-style';
import {Paper} from 'bigdatr-style';
import {DeadEnd} from 'bigdatr-style';
import {Boom} from 'bigdatr-style';
import {IconChevronLeft, IconChevronRight} from 'bigdatr-style/icon';
import {AU} from '~/feature/country/CountrySwitch';

export type CreativeItemViewProps = {
    creativeId?: string;
    creative?: Creative;
    autoPlay?: boolean;
    tight?: boolean;
    hasNextCreative?: boolean;
    openNextCreative?: () => void;
    hasPrevCreative?: boolean;
    openPrevCreative?: () => void;
};

export default function CreativeItemView(props: CreativeItemViewProps) {
    const params = useParams<{creativeId: string}>();
    const creativeId = props.creativeId || params.creativeId;
    const creativeMessage = api.creativeV2.publicCreativeItemV2.useRequest({key: creativeId});
    const events = useEventTracking();
    const shouldRequestData = !props.creative && creativeMessage.isEmpty;
    const viewer = useViewerUnsafe();

    useEffect(() => {
        if (shouldRequestData) {
            creativeMessage.request({creativeId});
        }
    }, [creativeId]);

    if (creativeMessage.isError) throw creativeMessage.requestError;
    if (shouldRequestData || creativeMessage.isFetching || creativeMessage.isRefetching)
        return <FlexCenter height="100%" children={<Loader />} />;

    const creative = props.creative || creativeMessage.response?.creativeV2.publicCreativeItemV2;
    const trackCreativeDownload = events.creativeDownload;
    const trackCreativeFullscreen = events.creativeFullscreen;
    const trackCreativeMute = events.creativeMute;
    const trackCreativePlaypause = events.creativePlaypause;
    const trackCreativeShare = events.creativeShare;

    if (!creative) {
        return (
            <Flex justifyContent="center">
                <Paper round maxWidth="40rem" px={4}>
                    <DeadEnd boom={Boom.notFound('creative')} />
                </Paper>
            </Flex>
        );
    }

    const renderShare = () => {
        const brand = creative.brandList[0] ? `${creative.brandList[0].label}'s ` : '';
        const subject = `${brand} creative on Bigdatr`;
        const body = `View ${brand} creative on Bigdatr:\n${creative.publicLink}`;

        return (
            <ButtonShare
                secondary
                onClick={() => trackCreativeShare(creative)}
                clipboardValue={creative.publicLink}
                clipboardLabel="Copy link"
                mailtoSubject={subject}
                mailtoBody={body}
                mailtoLabel="Share"
            />
        );
    };

    const renderDataEntryLink = () => {
        if (!viewer || !viewer.currentTeam.hasDataEntry) return null;
        return (
            <ButtonShare
                secondary
                clipboardValue={creative.dataEntryLink}
                clipboardLabel="Data entry"
            />
        );
    };

    const country = creative.country;

    const renderStats = () => {
        return (
            <TableKeyValue
                width="100%"
                zebra={true}
                data={{
                    Status: creative.status ? (
                        <Box display="inline-block">
                            <Badge color={creative.statusColor}>{creative.status}</Badge>
                        </Box>
                    ) : null,
                    Country: country ? <Text>{country}</Text> : null,
                    'Ad Type': creative.adType?.label ? (
                        <Text>{creative.adType?.label}</Text>
                    ) : null,
                    'Media Type': creative.mediaTypeList?.length ? (
                        <CommaList list={creative.mediaTypeList} />
                    ) : null,
                    Schedule: creative.schedule,
                    Brand: creative.brandList?.length ? (
                        <CommaList list={creative.brandList} />
                    ) : null,
                    Industry: creative.industryList?.length ? (
                        <CommaList list={creative.industryList} />
                    ) : null,
                    Category: creative.categoryList?.length ? (
                        <CommaList list={creative.categoryList} />
                    ) : null,
                    Product: creative.productList?.length ? (
                        <CommaList list={creative.productList} />
                    ) : null,
                    'Advertiser Name': creative.advertiserName ? (
                        <CommaList list={[{label: creative.advertiserName}]} />
                    ) : null,
                    'Advertiser Domain': creative.advertiserDomain ? (
                        <CommaList list={[{label: creative.advertiserDomain}]} />
                    ) : null,
                    ...(country === AU
                        ? {
                              Publisher: creative.publisherList.length ? (
                                  <CommaList list={creative.publisherList} />
                              ) : null,
                              'Media Owner': creative.mediaOwnerList.length ? (
                                  <CommaList list={creative.mediaOwnerList} />
                              ) : null,
                              Region: creative.regionList?.length ? (
                                  <CommaList list={creative.regionList} />
                              ) : null
                          }
                        : {
                              // intentionally labeling publications as publishers here
                              Publisher: creative.publicationList.length ? (
                                  <CommaList list={creative.publicationList} />
                              ) : null
                          })
                }}
            />
        );
    };

    return (
        <Wrapper
            maxHeight="inherit"
            height="100%"
            display="grid"
            alignItems="center"
            px={props.tight ? undefined : {sm: 3, md: 4}}
            data-testid="creative-preview"
        >
            <div>
                <Lightbox
                    minHeight="min(90vh, 50rem)"
                    content={
                        <Fragment>
                            <CreativeNavButton
                                title="Previous Creative"
                                position="left"
                                disabled={!props.hasPrevCreative}
                                onClick={props.openPrevCreative}
                            >
                                <IconChevronLeft size={'3rem'} />
                            </CreativeNavButton>
                            <CreativePreview
                                creativeItem={creative}
                                trackPlaypause={trackCreativePlaypause}
                                trackMute={trackCreativeMute}
                                trackFullscreen={trackCreativeFullscreen}
                                autoPlay={props.autoPlay}
                            />
                            <CreativeNavButton
                                title="Next Creative"
                                position="right"
                                disabled={!props.hasNextCreative}
                                onClick={props.openNextCreative}
                            >
                                <IconChevronRight size={'3rem'} />
                            </CreativeNavButton>
                        </Fragment>
                    }
                    details={
                        <Flex flexDirection="column" gap={3}>
                            <Text textStyle="heading2">
                                <CommaList
                                    list={creative?.brandList?.map((bb) => ({label: bb.label}))}
                                />
                            </Text>
                            <Grid gap={2} gridTemplateColumns="repeat(auto-fit, minmax(3rem, 1fr))">
                                <Button
                                    download
                                    href={creative.downloadPath}
                                    onClick={() => trackCreativeDownload(creative)}
                                    data-testid="CreativeProfile_downloadButton"
                                    children="Download"
                                />
                                {renderShare()}
                                {renderDataEntryLink()}
                            </Grid>
                            {renderStats()}
                        </Flex>
                    }
                />
            </div>
        </Wrapper>
    );
}

type Item = {to?: string; label: string};
function CommaList(props: {list: Item[]}) {
    const [showAll, setShowAll] = useState(false);
    const safeList = props.list || [];
    const [main = [], rest = []] = chunk(10, safeList);

    const list = (showAll ? safeList : main).flatMap((item: Item, index: number) => {
        const {to, label} = item;
        const link = <Text key={index}>{to ? <Link to={to}>{label}</Link> : label}</Text>;
        return index < safeList.length - 1 ? [link, ', '] : [link];
    });

    const showHideLink = showAll ? (
        <Link onClick={() => setShowAll(false)}>hide</Link>
    ) : (
        <Link onClick={() => setShowAll(true)}>+ {rest.length} more</Link>
    );

    return list.length > 0 ? (
        <Text>
            {list} {rest.length > 0 && showHideLink}
        </Text>
    ) : null;
}

const HoverWrapper = styled.div<{
    position: 'left' | 'right';
    hidden: boolean;
}>`
    display: ${(props) => (props.hidden ? 'none' : 'grid')};
    position: absolute;
    ${(props) => props.position}: 0;
    z-index: 1;
    height: 100%;

    path {
        fill: white;
        stroke: black;
        stroke-width: 0.25px;
        opacity: 0.75;
    }

    &:hover path {
        opacity: 1;
    }
`;

type CreativeNavBtnPropType = {
    title: string;
    disabled: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
    position: 'left' | 'right';
};
function CreativeNavButton(props: CreativeNavBtnPropType) {
    return (
        <HoverWrapper position={props.position} hidden={props.disabled}>
            <Clickable aria-label={props.title} disabled={props.disabled} onClick={props.onClick}>
                {props.children}
            </Clickable>
        </HoverWrapper>
    );
}
