import {Dropdown, DropdownList, formatDuration, ThemedLogo, Tooltip, useTheme} from 'bigdatr-style';
import {useAnalytics, styled} from 'bigdatr-style';
import {useViewer} from 'bigdatr-style';
import React from 'react';
import {Text} from 'bigdatr-style';
import {Box, FlexColumn, Relative} from 'bigdatr-style/layout';
import {Flex} from 'bigdatr-style/layout';
import {Clickable} from 'bigdatr-style';
import {MenuItem} from 'bigdatr-style';
import {Link} from 'bigdatr-style';
import {
    IconClock,
    IconDataExport,
    IconDocumentReportOutline,
    IconAdvertistingCreative,
    IconHouse,
    IconMenu,
    IconPerson,
    IconPieChart,
    IconSearch,
    IconSettings,
    IconLike,
    IconQuestionCircle,
    IconPeople,
    IconDataEntry,
    IconContentMove,
    IconMail,
    IconList
} from 'bigdatr-style/icon';
import DropdownSwitchTeams from '~/affordance/DropdownSwitchTeam';
import {useRoutes} from '~/app/Router';
import {LinkProps} from 'react-router-dom';
import TeamAvatar, {TeamAvatarIcon} from '~/affordance/TeamAvatar';
import useLastUsedMediaValuePage from '~/feature/media-value/useLastUsedMediaValuePage';
import useFeatureFlags from '~/util/useFeatureFlags';
import CountrySwitch, {AU, SupportedCountry} from '~/feature/country/CountrySwitch';
import {useCountry} from '~/feature/country/CountryContext';
import {useGlobalState} from './GlobalState';

type Props = {
    changeTeam: (teamId: string) => void;
    toggleNav: () => void;
    navOpen: boolean;
    showLinks: boolean;
};

const dataentryHost = process.env.BIGDATR_CLIENT_DATAENTRY_HOST;

const HELP_DOC_LINKS: {[key in SupportedCountry]: string} = {
    [SupportedCountry.AU]:
        'https://bigdatr.notion.site/Help-Support-Documentation-11ef2d1947644d6397d7e49199ad0be6',
    [SupportedCountry.NZ]:
        'https://bigdatr.notion.site/Help-Support-Documentation-New-Zealand-11bb97ff6f7880c98af4e5d2528254fb'
};

const iconSize = '1.5rem';
export default function AuthenticatedNavigation(props: Props) {
    const {changeTeam, showLinks, toggleNav, navOpen} = props;
    const viewer = useViewer();
    const analytics = useAnalytics();
    const theme = useTheme();
    const {country, isTrialing} = useCountry();
    const globalState = useGlobalState();

    const {nzMarket} = useFeatureFlags();

    const {
        dashboard,
        segmentList,
        userAdminUserList,
        userAdminTeamList,
        contentMover,
        exploreCreative,
        reportList,
        search,
        styleguideColors,
        notificationNews,
        mediaValueExpenditureReport,
        mediaValueGrowthAnalysis,
        mediaValueVerticalAnalysis,
        keywordList
    } = useRoutes();

    const {
        trialEndDate,
        products,
        hasAusMediaValue,
        hasTeamAdmin,
        hasDataEntry,
        hasMoreThanOneCountry
    } = viewer.currentTeam;

    const onSidebarItemClick = () => {
        if (navOpen && window.matchMedia(`(max-width: ${theme.breakpoints.xlMaxWidth})`).matches) {
            return toggleNav();
        }
    };

    const makeLink = ({
        icon,
        to,
        href,
        label,
        exact,
        newTab,
        testid,
        tooltipAlwaysVisible,
        tooltipText
    }: {
        icon: React.ReactNode;
        label: string;
        to?: LinkProps['to'];
        href?: string;
        exact?: boolean;
        newTab?: boolean;
        testid?: string;
        tooltipAlwaysVisible?: boolean;
        tooltipText?: string;
    }) => (
        <SidebarLink
            to={to}
            href={href}
            showLabel={navOpen}
            icon={icon}
            exact={exact}
            onClick={onSidebarItemClick}
            newTab={newTab}
            testId={testid}
            tooltipAlwaysVisible={tooltipAlwaysVisible}
            tooltipText={tooltipText}
        >
            {label}
        </SidebarLink>
    );

    const {hasUnreadNews} = globalState.news;

    const [lastUsedMediaValuePage] = useLastUsedMediaValuePage();

    const makeMediaValueLink = () => {
        const commonProps = {
            label: 'Media Value',
            icon: <IconDataExport size={iconSize} />,
            testid: 'appcues-app-navigation-media-value-link'
        };

        let to: LinkProps['to'] = {};
        switch (lastUsedMediaValuePage) {
            default:
            case 'Data':
                to = mediaValueExpenditureReport.to({});
                break;

            case 'Comparison':
                to = mediaValueGrowthAnalysis.to({});
                break;

            case 'Distribution':
                to = mediaValueVerticalAnalysis.to({});
                break;
        }
        return makeLink({...commonProps, to});
    };

    return (
        <FlexColumn
            height="100%"
            overflowY={{_: navOpen ? 'auto' : 'hidden', md: 'auto'}}
            borderRight={{md: 'outline'}}
        >
            {/* Fixed height is to prevent the hamburger icon from jumping with a closed nav */}
            <Flex gap={3} p={3} justifyContent="space-between" height="4rem">
                <Link to={dashboard.to()} display={{md: navOpen ? 'block' : 'none'}}>
                    <ThemedLogo />
                </Link>
                <Clickable onClick={toggleNav} aria-label="Toggle menu">
                    <IconMenu size={iconSize} />
                </Clickable>
            </Flex>

            {isTrialing && trialEndDate && (
                <Box pb={3}>
                    {makeLink({
                        label: `Trial ends in ${formatDuration(
                            new Date(),
                            new Date(trialEndDate)
                        )}`,
                        icon: <IconClock size={iconSize} />,
                        to: '/settings/billing'
                    })}
                </Box>
            )}

            {nzMarket && hasMoreThanOneCountry && (
                <Box pb={3}>
                    <Tooltip content="Change countries" placement="right">
                        <Box pl="10px" pr={2}>
                            <CountrySwitch showText={navOpen} />
                        </Box>
                    </Tooltip>
                </Box>
            )}

            {showLinks && products.length > 0 && (
                <FlexColumn gap="1.5rem" mb={4}>
                    {/* Only enable search on AU market */}
                    {country === SupportedCountry.AU &&
                        makeLink({
                            label: 'Search',
                            icon: <IconSearch size={iconSize} />,
                            to: search.to({})
                        })}
                    <Section title="Analysis" collapsed={!navOpen}>
                        {country === AU &&
                            makeLink({
                                label: 'Dashboard',
                                icon: <IconHouse size={iconSize} />,
                                to: dashboard.to(),
                                exact: true
                            })}
                        {makeLink({
                            label: 'Advertising Creative',
                            icon: <IconAdvertistingCreative size={iconSize} />,
                            to: exploreCreative.to({}),
                            testid: 'appcues-app-navigation-creatives-link'
                        })}
                        {hasAusMediaValue && country === AU && makeMediaValueLink()}
                        {!isTrialing &&
                            makeLink({
                                label: 'Reports',
                                icon: <IconDocumentReportOutline size={iconSize} />,
                                to: reportList.to(),
                                testid: 'appcues-app-navigation-reports-link'
                            })}
                    </Section>
                    <Section title="Data Management" collapsed={!navOpen}>
                        {country === AU
                            ? makeLink({
                                  label: 'Segments',
                                  icon: <IconPieChart size={iconSize} />,
                                  to: segmentList.to()
                              })
                            : makeLink({
                                  label: 'Keywords',
                                  icon: <IconList size={iconSize} />,
                                  to: keywordList.to({})
                              })}
                    </Section>

                    <Section title="Administration" collapsed={!navOpen}>
                        {makeLink({
                            label: 'News',
                            tooltipAlwaysVisible: hasUnreadNews,
                            tooltipText: hasUnreadNews ? 'You have 1 new message' : 'News',
                            icon: (
                                <Relative>
                                    <IconMail size={iconSize} />
                                    {hasUnreadNews && (
                                        <NotificationBadge data-testid="unread-notifications-badge" />
                                    )}
                                </Relative>
                            ),
                            to: notificationNews.to({})
                        })}
                        {makeLink({
                            label: 'Settings',
                            icon: <IconSettings size={iconSize} />,
                            to: '/settings'
                        })}
                        {makeLink({
                            label: 'Help',
                            icon: <IconQuestionCircle size={iconSize} />,
                            href: HELP_DOC_LINKS[country],
                            newTab: true
                        })}
                    </Section>
                    {(hasTeamAdmin || hasDataEntry) && (
                        <Section title="Bigdatr Administration" collapsed={!navOpen}>
                            {makeLink({
                                label: 'Styleguide',
                                icon: <IconLike size={iconSize} />,
                                to: styleguideColors.to()
                            })}
                            {hasTeamAdmin && (
                                <>
                                    {makeLink({
                                        label: 'Account Management',
                                        icon: <IconPeople size={iconSize} />,
                                        to: userAdminTeamList.to({})
                                    })}
                                    {makeLink({
                                        label: 'User Management',
                                        icon: <IconPerson size={iconSize} />,
                                        to: userAdminUserList.to({})
                                    })}
                                    {makeLink({
                                        label: 'Content Mover',
                                        icon: <IconContentMove size={iconSize} />,
                                        to: contentMover.to()
                                    })}
                                </>
                            )}
                            {hasDataEntry && (
                                <>
                                    {makeLink({
                                        label: 'Data Entry',
                                        icon: <IconDataEntry size={iconSize} />,
                                        href: dataentryHost
                                    })}
                                </>
                            )}
                        </Section>
                    )}
                </FlexColumn>
            )}
            <Box mt="auto" mb={2}>
                <Dropdown
                    maxWidth={{md: '20rem'}}
                    direction="top"
                    topOffset={16}
                    menu={() => (
                        <>
                            <MenuItem large to="/settings/team">
                                <TeamAvatar
                                    team={viewer.currentTeam}
                                    subtitle={viewer.user.username}
                                />
                            </MenuItem>
                            <DropdownSwitchTeams viewer={viewer} changeTeam={changeTeam} />
                            <DropdownList>
                                <MenuItem small onClick={() => analytics.chat()}>
                                    Contact Us
                                </MenuItem>
                                <MenuItem small href="https://bigdatr.com/licenseagreement">
                                    License Agreement
                                </MenuItem>
                                <MenuItem small href="https://bigdatr.com/terms-of-use">
                                    Terms of Use
                                </MenuItem>
                                <MenuItem small href="https://bigdatr.com/privacy-policy">
                                    Privacy Policy
                                </MenuItem>
                                <MenuItem small to="/logout">
                                    Logout
                                </MenuItem>
                            </DropdownList>
                        </>
                    )}
                    control={() => (
                        <SidebarLink
                            icon={
                                <TeamAvatarIcon
                                    teamId={viewer.currentTeam.id}
                                    teamName={viewer.currentTeam.name}
                                    size={iconSize}
                                />
                            }
                            showLabel={navOpen}
                            noTooltip
                        >
                            {viewer.currentTeam.name}
                        </SidebarLink>
                    )}
                />
            </Box>
        </FlexColumn>
    );
}

function SidebarLink({
    children,
    icon,
    to,
    href,
    showLabel,
    noTooltip,
    testId,
    exact,
    onClick,
    newTab,
    tooltipAlwaysVisible,
    tooltipText
}: {
    children: string;
    icon: React.ReactNode;
    showLabel: boolean;
    to?: LinkProps['to'];
    href?: string;
    noTooltip?: boolean;
    active?: boolean;
    testId?: string;
    exact?: boolean;
    onClick?: () => void;
    newTab?: boolean;
    tooltipAlwaysVisible?: boolean;
    tooltipText?: string;
}) {
    let tooltipContent = showLabel || noTooltip ? undefined : children;
    if (tooltipAlwaysVisible) tooltipContent = tooltipText;
    return (
        <Tooltip content={tooltipContent} placement="right" alwaysVisible={tooltipAlwaysVisible}>
            <MenuItem
                small
                data-testid={testId}
                exact={exact}
                to={to}
                href={href}
                navLink
                onClick={onClick}
                target={newTab ? '_blank' : undefined}
                aria-label={children}
            >
                <Flex gap={2} alignItems="center">
                    <Box flexShrink={0} children={icon} />
                    {showLabel && children}
                </Flex>
            </MenuItem>
        </Tooltip>
    );
}

function Section({
    title,
    children,
    collapsed
}: {
    title: string;
    children: React.ReactNode;
    collapsed: boolean;
}) {
    return (
        <FlexColumn gap={1}>
            {!collapsed && <Text px={3} textStyle="smallLabel" color="muted" children={title} />}
            {children}
        </FlexColumn>
    );
}

const NotificationBadge = styled.div`
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    background-color: ${(p) => p.theme.colors.red};
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 10rem;
`;
