import {useCallback, useMemo} from 'react';
import {Brand, Category, Creative, Industry, debounce, useAnalytics} from 'bigdatr-style';
import {
    BreakdownDefinitionUpsert,
    CreativeDateRangeFilter,
    MediaDateRangeFilter
} from '~/report/data/ReportItemDefinitionTypes';
import {useCountryUnsafe} from '~/feature/country/CountryContext';

//
// @intent
// Used to track interactions that cant be determined via page tracking
// must use the schema:
// entity:verb
//
// Good:
//      creative:download, brand:share
//
// Bad:
//      brandProfile:request_creativeItemDownload, brandProfile:change_brand

export default function useEventTracking() {
    const analytics = useAnalytics();

    // country context will not be initialised on public routes, which means we could not know in
    // which country the user is in. the alternative is to add `country` param to each tracking
    // method we have, and pass it in where its consumed
    const country = useCountryUnsafe()?.country;

    const addCountry = () => {
        if (country) return {country};
    };

    // we're debouncing search tracking separately, because when customer success is looking at
    // analytics, their reports are hard to follow if the users type really slowly.
    // which means they are seeing events like this:
    // creative:search = c
    // creative:search = ca
    // creative:search = car
    const onCreativeAdContentSearch = useCallback(
        debounce((searchString: string) => {
            analytics.trackEvent('creative:search', {searchString, ...addCountry()});
        }, 2000),
        []
    );

    const tracking = useMemo(
        () => ({
            // PDF
            pdfDownload: () => analytics.trackEvent('pdf:download', {...addCountry()}),

            // Creative
            creativeShare: (event: Creative) =>
                analytics.trackEvent('creative:share', {...event.toTracking(), ...addCountry()}),
            creativeDownload: (event: Creative) =>
                analytics.trackEvent('creative:download', {...event.toTracking(), ...addCountry()}),
            creativePlaypause: (event: Creative) =>
                analytics.trackEvent('creative:playpause', {
                    ...event.toTracking(),
                    ...addCountry()
                }),
            creativeMute: (event: Creative) =>
                analytics.trackEvent('creative:mute', {...event.toTracking(), ...addCountry()}),
            creativeView: (event: Creative) =>
                analytics.trackEvent('creative:view', {...event.toTracking(), ...addCountry()}),
            creativeFilter: (type: string, value: unknown) =>
                analytics.trackEvent('creative:filter', {type, value, ...addCountry()}),
            creativeFullscreen: (event: string) =>
                analytics.trackEvent('creative:fullscreen', {type: event, ...addCountry()}),
            creativeListDownload: (params: {creativeList: string[]}) =>
                analytics.trackEvent('creative:downloadList', {
                    creativeIdList: params.creativeList,
                    ...addCountry()
                }),
            creativeSearch: onCreativeAdContentSearch,
            creativeSort: (sortOption: string) => {
                const prettySortLabel = sortOption === 'score' ? 'relevancy' : sortOption;
                analytics.trackEvent('creative:sort', {
                    sortOption: prettySortLabel,
                    ...addCountry()
                });
            },

            // Creatives
            creativesDownloadOpen: () =>
                analytics.trackInteraction('creativesDownloadOpen', {...addCountry()}),

            creativesDownload: (
                payload: {type: 'csv' | 'media'} | {type: 'pdf'; timeTakenMs: number}
            ) => analytics.trackEvent('creatives:download', {...payload, ...addCountry()}),

            // Media Value
            mediaValueFilter: (type: string, value: unknown) =>
                analytics.trackEvent('mediaValue:filter', {type, value}),

            // Data Export
            dataExportTemplateSelection: (payload: {template: string; type: string}) => {
                analytics.trackEvent('dataExport:templateSelection', payload);
            },
            dataExportCsvDownload: (payload: {type: string; filters: string}) => {
                analytics.trackEvent('dataExport:csvDownload', payload);
            },

            // dashboard
            shortcutClick: (payload: Brand | Category | Industry) =>
                analytics.trackEvent('shortcut:click', payload.trackingType),
            shortcutEdit: () => analytics.trackEvent('shortcut:edit'),

            // Charts
            chartDownload: (payload: {
                node: {name: string; type: string};
                lens: Array<string>;
                filter: unknown;
            }) => {
                analytics.trackEvent('chart:download', payload);
            },
            dataTableCsvExport(payload: BreakdownDefinitionUpsert['filter']) {
                analytics.trackEvent('dataTable:csvExport', payload);
            },

            // news section
            newsArticleView(payload: {articleName: string}) {
                analytics.trackEvent('newsArticle:read', {...payload, ...addCountry()});
            },

            // public creative CTA
            registerCtaClick: (identifier: string) =>
                analytics.trackEvent('registerCTA:click', {identifier}),

            // user registration completed:
            registerComplete: () => analytics.trackEvent('register:complete', {...addCountry()}),

            dateSelection: (payload: {
                type: 'spend' | 'creative';
                date: MediaDateRangeFilter | CreativeDateRangeFilter;
            }) => {
                // doing this because its unreadable if we just dump the whole date object as is
                const {type, date} = payload;
                if (date.absolute) {
                    const {endDate, startDate} = date.absolute;
                    analytics.trackEvent('datePicker:select', {
                        type,
                        startDate,
                        endDate,
                        ...addCountry()
                    });
                }
                if (date.relative) {
                    analytics.trackEvent('datePicker:select', {
                        type,
                        rollingMonths: date.relative.months,
                        ...('includeForecasted' in date.relative && {
                            includeForecastedData: date.relative.includeForecasted
                        }),
                        ...addCountry()
                    });
                }
            }
        }),
        [analytics]
    );
    return tracking;
}
