import {EntityAvatarAuto} from 'bigdatr-style';
import {Team} from 'bigdatr-style';
import {Text} from 'bigdatr-style';
import {useTheme} from 'bigdatr-style';
import {hash} from 'bigdatr-style';
import {Flex, Grid} from 'bigdatr-style/layout';
import React from 'react';

export default function TeamAvatar(props: {
    team: Team;
    subtitle?: string;
    reverseSubtitle?: boolean;
}) {
    const {team} = props;
    const {reverseSubtitle} = props;

    const title = (
        <Text textStyle="strong" lineHeight="1rem">
            {team.name}
        </Text>
    );
    const subtitle = props.subtitle ? <Text textStyle="copy">{props.subtitle}</Text> : null;

    return (
        <Grid gridTemplateColumns="2rem auto" alignItems="center" gap={3}>
            <TeamAvatarIcon teamId={team.id} teamName={team.name} />
            <Flex flexDirection={reverseSubtitle ? 'column-reverse' : 'column'}>
                {title}
                {subtitle}
            </Flex>
        </Grid>
    );
}

export const TeamAvatarIcon = ({
    teamName,
    teamId,
    size = '2rem'
}: {
    teamName: string;
    teamId: string;
    size?: string;
}) => {
    const {colors} = useTheme();
    const hh = hash(teamName);
    const num = parseInt(hh[2]) + parseInt(hh[3]);
    return (
        <EntityAvatarAuto
            color={colors.palette[num]}
            style={{width: size, height: size}}
            name={teamId}
        />
    );
};
