import {Link, Text} from 'bigdatr-style';
import {Li, Ol, Ul} from 'bigdatr-style/layout';
import React from 'react';
import HubspotForm from '../../../HubspotForm';

export default function DataExportLaunch() {
    return (
        <>
            <p>
                Bigdatr released new updates in the Media Value tool with custom date range
                comparisons and media value distribution insights.
            </p>

            <Text textStyle="heading4">New Features</Text>
            <p>
                <Text textStyle="strong">Media Value Comparison</Text>: Quickly analyse media budget
                volumes and track changes in media investments over time. Identify areas of
                increasing competition and adapt your strategy to mitigate exposure risks.
            </p>
            <p>
                <Text textStyle="strong">Media Value Distribution</Text>: Access detailed percentage
                distributions of media investment across various periods. Report on competitor media
                mix, budget allocation, and Share of Voice with ease—no complex formulas required!
            </p>

            <Text textStyle="heading4">How to Get Started</Text>
            <Ol>
                <Li>
                    <Link to="/explore-media-value/">Visit Media Value tool</Link> and choose your
                    analysis method
                </Li>
                <Li>
                    Get started with various templates to view the most common insights marketers
                    are tracking
                </Li>
                <Li>
                    For Media Value pros — choose a custom date range and apply your chosen filters
                </Li>
            </Ol>

            <Text textStyle="heading4">Got Questions?</Text>

            <Ul>
                <Li>
                    <Link href="https://hubs.ly/Q02Kmp-F0">
                        Request a demo from our Platform Expert
                    </Link>{' '}
                    to show your team how you can use these insights to enhance your media planning
                    and buying strategies
                </Li>
                <Li>Chat to us for 1:1 support</Li>
            </Ul>

            <Text textStyle="heading4">Don’t have access to Media Value?</Text>
            <p>
                Made for marketers to track competitor investments, Media Value gives you the power
                to make data-driven decisions with your media planning and buying. Choose from 75+
                industries and monitor competitor’s advertising expenditure across online and
                offline media channels.
            </p>

            <HubspotForm
                formId="770fc60f-3a80-4f0c-a8ad-662bdebe6f12"
                targetId="form-data-export-media-value-interest"
                openChatOnSubmit
            />
        </>
    );
}
