import {ObjectSchema} from 'react-enty';
import {ArraySchema} from 'react-enty';
import {EntitySchema} from 'react-enty';
import {Schema} from 'react-enty';
import {ClassSchema, InfinitePaginationSchema} from 'bigdatr-style';
import {ResponseMeta} from 'bigdatr-style';
import {Schemas} from 'bigdatr-style';
import {ReportSchema} from '~/report/data/ReportApi';
import {SegmentSchema, segment} from '~/segment/data/SegmentApi';
import {CreativeApiV2Schema, creative, creativeList} from '~/creative/data/CreativeApi';
import {MediaValueResult} from '~/graphqlTypes';
import {FilterSchema} from '~/feature/filter/api/FilterApi';
import {SearchSchema} from '~/search/data/SearchApi';
import {DashboardSchema} from '~/dashboard/data/DashboardApi';
import {UserSchema} from '~/user/data/UserApi';
import {TeamSchema} from '~/user/data/TeamApi';

// Entity Helpers
const edgeList = (node: Schema) =>
    new ObjectSchema({edges: new ArraySchema(new ObjectSchema({node}))});

//
// Entities

const responseMeta = new EntitySchema('responseMeta', {
    id: (e) => e?.id || 'NONE',
    shape: new ClassSchema(ResponseMeta, {})
});

// Graph Entities
const creativeEdgeList = edgeList(creative);

export default new ObjectSchema({
    ...CreativeApiV2Schema,
    ...DashboardSchema,
    ...FilterSchema,
    ...ReportSchema,
    ...Schemas,
    ...SearchSchema,
    ...SegmentSchema,
    ...UserSchema,
    ...TeamSchema,
    responseMeta,
    creative: new ObjectSchema({
        creativeList: creativeEdgeList,
        creativeItem: creative
    }),
    mediaValue: new ObjectSchema({
        rows: new InfinitePaginationSchema<MediaValueResult & {id: string}>('dataTable', {
            id: (x) => x.id,
            shape: new ObjectSchema({
                labels: new ObjectSchema({
                    adType: Schemas.adTypeList,
                    brand: Schemas.brandList,
                    campaign: Schemas.campaignList,
                    category: Schemas.categoryList,
                    industry: Schemas.industryList,
                    mediaOwner: Schemas.mediaOwnerList,
                    mediaType: Schemas.mediaTypeList,
                    product: Schemas.productList,
                    publisher: Schemas.publisherList,
                    region: Schemas.regionList,
                    segment: new ArraySchema(segment)
                })
            }),
            pageNumber: (x) => x.pageInfo.currentPage,
            flatten: (list, mostRecent) => {
                return {
                    ...mostRecent,
                    rows: list.flatMap((i) => i.rows),

                    // Looks weird cause it's merging Record<string, Array<T>>;
                    labels: list
                        .map((ii) => ii.labels)
                        .reduce((r, i) => {
                            Object.entries(i).forEach(([key, value]) => {
                                r[key] ||= [];
                                r[key] = r[key].concat(value);
                            });
                            return r;
                        })
                };
            }
        })
    })
});

export {creativeList};
