import {EntityApi as ApiFactory} from 'react-enty';
import EntitySchema from './EntitySchema';
import Endpoints from '~/app/Endpoints';
import DashboardApi, {DashboardApiType} from '~/dashboard/data/DashboardApi';
import team from '~/user/data/TeamApi';
import {GraphqlRequest, Viewer, createPollingGenerator} from 'bigdatr-style';
import user, {UserApi} from '~/user/data/UserApi';
import {ReportApi, ReportApiType} from '~/report/data/ReportApi';
import {EntyRequest} from 'bigdatr-style';
import {FilterApi, FilterApiType} from '~/feature/filter/api/FilterApi';
import {SegmentApi, SegmentApiType} from '~/segment/data/SegmentApi';
import {default as PdfApi, PdfApiType} from '~/pdf/data/PdfApi';
import {SearchApi, SearchApiType} from '~/search/data/SearchApi';
import {TeamApiType} from '~/user/data/TeamApi';
import {CreativeV2ApiType, CreativeApiV2} from '~/creative/data/CreativeApi';
import {MediaValueApi, MediaValueApiType} from '~/services/mediaValue/mediaValueApi';
import {TrackingApiType, default as TrackingApi} from '~/tracking/TrackingApi';

const {EntityApi, EntityStrict} = Endpoints;

const CreativeDownloadExecuteQuery = () =>
    import('~/brand/data/CreativeDownloadExecuteQuery.graphql');
const CreativeDownloadStatusQuery = () =>
    import('~/brand/data/CreativeDownloadStatusQuery.graphql');
const ViewerQuery = EntityStrict(() => import('~/app/data/ViewerQuery.graphql'));

export async function viewerQuery({teamId}: {teamId: string}, meta) {
    const viewerResponse = await ViewerQuery(undefined, meta);
    viewerResponse.viewer.currentTeamId = teamId;
    return viewerResponse;
}

const Api: CreativeV2ApiType &
    DashboardApiType &
    FilterApiType &
    MediaValueApiType &
    PdfApiType &
    ReportApiType &
    SearchApiType &
    SegmentApiType &
    TeamApiType &
    TrackingApiType &
    UserApi & {
        Provider: React.ComponentType<any>;
        useRemove: () => (entity: string, id: string) => void;
        viewer: GraphqlRequest<{viewer: Viewer}, {teamId: string}>;

        /** legacy */
        creative: {
            creativeDownload: EntyRequest<unknown>;
        };
    } = ApiFactory(
    {
        viewer: viewerQuery,

        //
        // legacy creative
        creative: {
            creativeDownload: async function creativeDownload(payload, meta) {
                const pollingData = createPollingGenerator({
                    createRequest: async () => {
                        // This api uses termatico and so doesn't work in dev. Uncomment this to stub the requests
                        /*
                        return {jobExecution: {executeCreativeDownload: {executionId: 'foo'}}};
                        */
                        const response = await EntityApi(CreativeDownloadExecuteQuery)(
                            payload,
                            meta
                        );
                        return response.jobExecution.executeCreativeDownload;
                    },
                    pollRequest: async (createResponse) => {
                        // This api uses termatico and so doesn't work in dev. Uncomment this to stub the requests
                        /*
                        return {jobExecution: {executionStatus: {id: 'foo', status: 'SUCCEEDED', finalOutput: { downloadUrl: '' }}}};
                        */
                        const response = await EntityApi(CreativeDownloadStatusQuery)(
                            createResponse,
                            meta
                        );
                        return response;
                    },
                    pollWhile: (data: any) =>
                        data?.jobExecution?.executionStatus?.status === 'RUNNING'
                });

                // Enty can handle generators but not async generators
                // Can side step this by flattening the whole request to one promise
                let lastPayload;
                for await (const payload of pollingData) {
                    lastPayload = payload;
                }
                return lastPayload;
            }
        },

        ...CreativeApiV2,
        ...DashboardApi,
        ...FilterApi,
        ...MediaValueApi,
        pdf: PdfApi,
        team,
        ...ReportApi,
        ...SearchApi,
        ...SegmentApi,
        ...TrackingApi,
        user
    },
    EntitySchema
);

export const EntityProvider = Api.Provider;
export default Api;
