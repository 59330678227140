type PlacementTypeInput = {
    name: string;
};
export default class PlacementType {
    private data: PlacementTypeInput;
    type = 'placementType' as const;

    constructor(input: PlacementTypeInput) {
        this.data = input;
    }

    get id() {
        return this.data.name;
    }

    get label() {
        return this.data.name;
    }
}
