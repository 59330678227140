import React from 'react';
import {Text} from 'bigdatr-style';
import HubspotForm from '../../../HubspotForm';

export default function NzComingSoon() {
    return (
        <>
            <p>
                You won’t be guessing what resonates with everyday Kiwis anymore. Instead, you’ll be
                one step closer to having near real-time access to digital creative insights from
                New Zealand.
            </p>
            <p>
                Whether you’re looking to enter the market or enhance your current strategies,
                having coverage of New Zealand’s digital creative market will help you embrace the
                culture and better understand local messaging, creative trends and offers in market.
            </p>
            <p>
                Upgrade starts at $850 per month<sup>1</sup> and options will be announced September
                2024.<sup>2</sup>
            </p>
            <Text textStyle="strong">
                Click below if you’re interested in New Zealand digital creative data for your team.
            </Text>
            <HubspotForm
                formId="a2376745-1935-433b-9d57-8b3402d2b49f"
                targetId="form-nz-expression-of-interest"
            />
            <Text textStyle="disclaimer">
                <sup>1</sup>Pricing is subject to change based on your subscription plan, and the
                figures provided are for indication purposes only.
                <br />
                <sup>2</sup>Dates provided are for informational purposes only and are subject to
                change without prior notice.
            </Text>
        </>
    );
}
