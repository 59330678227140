import {formatDuration} from '../affordance/Text';

type ResponseMetaInput = {
    id: string;
    start: Date;
    end: Date;
    url: string;
    query: string;
    variables: Record<string, any>;
    errors: any;
};

export default class ResponseMeta {
    id: string;
    start: Date;
    end: Date;
    url: string;
    query: string;
    variables: Record<string, any>;
    errors: any;

    constructor(input: ResponseMetaInput) {
        for (const key in input) {
            this[key] = input[key];
        }
    }

    get duration() {
        return this.end.getTime() - this.start.getTime();
    }
    get humanDuration(): string {
        return formatDuration(this.start, this.end);
    }

    findError(paths: Array<string>) {
        const errors = this.errors || [];
        if (errors) {
            const throwableError = errors.find(
                (error) => !error.path || paths.join('.').includes(error.path.join('.'))
            );
            if (throwableError) return throwableError;
        }
    }
}
