import {GraphqlRequest} from 'bigdatr-style';
import {EntityStrict} from '~/app/Endpoints';
import {
    TrackingMutationSubmitHubspotFormArgs,
    TrackingMutationSubmitInternationalTeamOnboardedFormArgs
} from '~/graphqlTypes';

const SubmitForm = EntityStrict(() => import('~/tracking/SubmitForm.graphql'));
const SubmitInternationalTeamOnboardedForm = EntityStrict(
    () => import('~/tracking/SubmitInternationalTeamOnboardedForm.graphql')
);

export type TrackingApiType = {
    tracking: {
        submitHubspotForm: GraphqlRequest<boolean, TrackingMutationSubmitHubspotFormArgs>;
        submitInternationalTeamOnboardedForm: GraphqlRequest<
            boolean,
            TrackingMutationSubmitInternationalTeamOnboardedFormArgs
        >;
    };
};

export default {
    tracking: {
        submitHubspotForm: SubmitForm,
        submitInternationalTeamOnboardedForm: SubmitInternationalTeamOnboardedForm
    }
};
