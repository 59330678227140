import {safeLazyImport, BoringRoute, LazyRoute, Parse} from 'bigdatr-style';
import {OnboardFollowBrandsArgs} from './OnboardFollowBrands';

const OnboardWelcome = safeLazyImport(() => import('./OnboardWelcome'));
const OnboardSelectIndustryGroup = safeLazyImport(() => import('./OnboardSelectIndustryGroup'));
const OnboardInviteTeam = safeLazyImport(() => import('./OnboardInviteTeam'));
const OnboardFollowBrands = safeLazyImport(() => import('./OnboardFollowBrands'));

export default {
    // au and nz routes
    onboardStart: BoringRoute({
        path: '/welcome',
        component: OnboardWelcome
    }),
    onboardInviteTeam: BoringRoute({
        path: '/welcome/invite-team',
        component: OnboardInviteTeam
    }),

    // au specific routes
    onboardSelectIndustryGroup: BoringRoute({
        path: '/welcome/select-data',
        component: OnboardSelectIndustryGroup
    }),
    onboardFollowBrands: LazyRoute<OnboardFollowBrandsArgs>({
        path: '/welcome/follow',
        parse: {
            q: Parse.query.JSON((x) => x as OnboardFollowBrandsArgs['q'])
        },
        component: OnboardFollowBrands
    })
};
