import {Select} from 'bigdatr-style';
import {IconFlagAU, IconFlagNZ} from 'bigdatr-style/icon';
import {Flex} from 'bigdatr-style/layout';
import React from 'react';
import {useCountry} from './CountryContext';

export enum SupportedCountry {
    AU = 'Australia',
    NZ = 'New Zealand'
}
export const AU = SupportedCountry.AU;
export const NZ = SupportedCountry.NZ;

export const countryLabels = {
    AU: 'Australia',
    NZ: 'New Zealand'
};

export default function CountrySwitch(props: {showText: boolean}) {
    const {country, setCountry} = useCountry();

    function label(input: {option: SupportedCountry; showText: boolean}) {
        const {showText} = input;
        switch (input.option) {
            case AU:
                return (
                    <Flex gap={2} alignItems="center">
                        <IconAustralia />
                        {showText && countryLabels.AU}
                    </Flex>
                );
            case NZ:
                return (
                    <Flex gap={2} alignItems="center">
                        <IconNewZealand />
                        {showText && countryLabels.NZ}
                    </Flex>
                );
        }
    }
    return (
        <Select
            transparent
            value={country}
            onChange={setCountry}
            options={[AU, NZ]}
            controlLabel={(option) => option && label({option, showText: props.showText})}
            label={(option) => option && label({option, showText: true})}
            hideCaret
        />
    );
}

export function IconAustralia(props) {
    return <IconFlagAU size="1.5rem" {...props} />;
}

export function IconNewZealand(props) {
    return <IconFlagNZ size="1.5rem" {...props} />;
}
