import {Viewer} from 'bigdatr-style';
import {SupportedCountry} from './CountrySwitch';

// users can have a localStorage value that says NZ, but their current team may not have NZ
// access - eg by switching teams, or losing access due to unsubscribing or not paying bill.
// same goes for user session metadata.
export default function getSafeCountry(viewer: Viewer) {
    // we grab session metadata first because thats what the user last used (assuming not the
    // first login). we do this for UX reasons as it was the 'last action'
    const lastCountryFromSession = viewer.user.sessionMetadata?.country as SupportedCountry;
    // however we check if that aligns with current team permissions (by checking permissions
    // directly from the viewer). if thats ok, we can use session metadata as the local storage country.
    const countriesUserHasAccessTo = viewer.currentTeam.countriesWithAccess;

    if (countriesUserHasAccessTo.includes(lastCountryFromSession)) {
        return {hasAccess: true, country: lastCountryFromSession};
    }

    // if session metadata country isn't a country the user is subscribed to, we get any other
    // country the user has access to
    const countryWithAccess = countriesUserHasAccessTo.find(
        (country) => country !== lastCountryFromSession
    );
    if (countryWithAccess) return {hasAccess: true, country: countryWithAccess};

    // if no country is found, the consumer of this function should use this as a sign to redirect
    // to the no access page, because having no country means the user has no access
    return {hasAccess: false, country: SupportedCountry.AU};
}
