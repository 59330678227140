import {ClassSchema, EntyRequest, GraphqlRequest} from 'bigdatr-style';
import {EntitySchema, ArraySchema, ObjectSchema} from 'react-enty';
import {
    ReportItem,
    ReportMutationReportItemCreateArgs,
    ReportMutationReportItemUpdateArgs,
    ReportMutationReportItemRemoveArgs,
    ReportQueryQueryVariables,
    ReportSearchQueryVariables,
    ReportRemoveManyMutationVariables,
    ReportShareManyMutationVariables,
    ReportV2QueryReportListArgs
} from '~/graphqlTypes';
import Endpoints, {Meta} from '~/app/Endpoints';
import Report from './Report';
const {EntityStrict} = Endpoints;

const ReportQuery = EntityStrict(() => import('./ReportQuery.graphql'));
const ReportListQuery = EntityStrict(() => import('./ReportListQuery.graphql'));
const ReportUpdateMutation = EntityStrict(() => import('./ReportUpdateMutation.graphql'));
const ReportCreateMutation = EntityStrict(() => import('./ReportCreateMutation.graphql'));
const ReportRemoveMutation = EntityStrict(() => import(`./ReportRemoveMutation.graphql`));
const ReportRemoveManyMutation = EntityStrict(() => import(`./ReportRemoveManyMutation.graphql`));
const ReportDuplicateMutation = EntityStrict(() => import(`./ReportDuplicateMutation.graphql`));
const ReportItemCreateMutation = EntityStrict(() => import('./ReportItemCreateMutation.graphql'));
const ReportItemRemoveMutation = EntityStrict(() => import('./ReportItemRemoveMutation.graphql'));
const ReportItemUpdateMutation = EntityStrict(() => import('./ReportItemUpdateMutation.graphql'));
const ReportShareManyMutation = EntityStrict(() => import('./ReportShareManyMutation.graphql'));

const ReportSearchQuery = EntityStrict(() => import('./ReportSearchQuery.graphql'));

const reportItem = new EntitySchema('reportItem');
const reportItemList = new ArraySchema(reportItem);
const report = new EntitySchema('report');
export const reportList = new ArraySchema(report);

report.shape = new ClassSchema(Report, {items: reportItemList});

export const ReportSchema = {
    reportV2: new ObjectSchema({
        report,
        reportCreate: report,
        reportRemoveMany: reportList,
        reportShareMany: reportList,
        reportUpdate: report,
        reportList,
        reportItemCreate: reportItem,
        reportItemUpdate: reportItem
    })
};

type Request<T> = EntyRequest<{reportV2: T}>;

export type ReportApiType = {
    reportV2: {
        report: Request<{report: Report}>;
        reportCreate: Request<{reportCreate: Report}>;
        reportUpdate: Request<{reportUpdate: Report}>;
        reportShareMany: GraphqlRequest<
            {reportV2: {reportShareMany: Report}},
            ReportShareManyMutationVariables
        >;
        reportRemove: Request<{reportRemove: boolean}>;
        reportRemoveMany: GraphqlRequest<
            {reportV2: {reportRemoveMany: Report}},
            ReportRemoveManyMutationVariables
        >;
        reportDuplicate: Request<{reportDuplicate: Report}>;
        reportList: GraphqlRequest<
            {reportV2: {reportList: Array<Report>}},
            ReportV2QueryReportListArgs
        >;
        reportItemCreate: Request<{report: Report}>;
        reportItemUpdate: Request<{reportItemUpdate: ReportItem}>;
        reportItemRemove: Request<{report: Report}>;
        reportSearch: GraphqlRequest<
            {reportV2: {reportSearch: Array<Report>}},
            ReportSearchQueryVariables
        >;
    };
};

export const ReportApi = {
    reportV2: {
        report: ReportQuery,
        reportCreate: ReportCreateMutation,
        reportUpdate: ReportUpdateMutation,
        reportShareMany: ReportShareManyMutation,
        reportRemove: ReportRemoveMutation,
        reportRemoveMany: async (vars, meta) => {
            return await ReportRemoveManyMutation(vars, meta);
        },
        reportDuplicate: ReportDuplicateMutation,
        reportList: ReportListQuery,
        reportItemCreate: async ({reportItem, reportId}, meta) => {
            await ReportItemCreateMutation(
                {input: reportItem} as ReportMutationReportItemCreateArgs,
                meta
            );
            return ReportQuery({id: reportId}, meta);
        },
        reportItemRemove: async ({reportItem, reportId}, meta) => {
            await ReportItemRemoveMutation(
                {input: {id: reportItem.id, reportId}} as ReportMutationReportItemRemoveArgs,
                meta
            );
            return ReportQuery({id: reportId} as ReportQueryQueryVariables, meta);
        },
        reportItemUpdate: ({input}: ReportMutationReportItemUpdateArgs, meta: Meta) => {
            return ReportItemUpdateMutation({input}, meta);
        },
        reportSearch: ReportSearchQuery
    }
};
